import React from 'react';
import { Link }  from 'react-router-dom';
import 'animate.css';
import Video from './drake.mp4';


export default () => (
	<div className='landing'>
		<div className='info'>

	

<div class="overlay">
		<h2>
		New Wave
		</h2>
		<h3>Property Group</h3>
			<Link to='/contactpage'>
			<button className="button1">BOOK YOUR CONSULTATION</button>
			</Link>
		</div>
		</div>
	</div>
);
