import React from 'react';
import Img from '../images/testimony1.png';
import Img_people from '../images/people.jpg';
import Img_arch from '../images/architect.jpg';
import Img_offer from '../images/fp-post.jpeg';

export default () => (

	<div className='aboutpage-CN'>
		<h1>About New Wave Property Group</h1>
		<div class='aboutpage-img-div'>
			<img src={Img_people} class="about_page_img1"></img>
			<div className='abt-back'>
				<h4>
				WE ARE COMMUNITY BUILDERS
				</h4>
				<p>
				New Wave Property Group (NWPG) is more than just a real estate and construction company. We are the catalyst for positive transformation in the Canadian housing market. Our commitment lies in creating opportunities for investors to play an integral role in shaping a more accessible, inclusive future of home ownership in Canada.

				</p>
			</div>
		</div>
		<br></br>
		<div class='aboutpage-img-div'>
			<div className='abt-back-2'>
				<h4>
				WHAT WE OFFER
				</h4>
				<h2>We Build for People </h2>
				<p>
				Our firm footing in the industry, rooted in over eight years of experience, allows us to drive value to our investors. At the same time, we continue to build high-quality, affordable homes that address the growing demand.
				</p>
			</div>
			<img src={Img_offer} class="about_page_img_offer"></img>
		</div>
		<br></br>

		<div class='aboutpage-img-div'>
		<img src={Img_arch} class="about_page_img1"></img>
			<div className='abt-back-2'>
				<h4>
				WHY WORK WITH US
				</h4>
				<p>By investing with NWPG, you're making a statement - you're empowering Canada to set a global standard, demonstrating that every citizen deserves the opportunity to own a home.
				</p>
				<p>
					Together, we can change the narrative of housing scarcity, turning it into a story of abundance and community. With NWPG, you're not just investing in property; you're investing in a future where every Canadian can call a place their own.
				</p>
			</div>	
		</div>
		<br></br>

	
		<div className="team">
		<h4 className="team-title">Our Team</h4>
		<hr className="underline"></hr>
		<div className="aboutpage-split">
			<img src={Img} class="about-img"></img>
			<div className="aboutpage-text">
				<h4 class="title"> Founder</h4>
				<h4 class="name"> John Doe</h4>
				<p>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.				</p>
			</div>
		</div>
		<div className="aboutpage-split">
			<img src={Img} class="about-img"></img>
			<div className="aboutpage-text">
				<h4 class="title"> Partner</h4>
				<h4 class="name"> John Doe</h4>
			<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.				</p>
			</div>
		</div>
		<div className="aboutpage-split">
			<img src={Img} class="about-img"></img>
			<div className="aboutpage-text">
				<h4 class="title"> Civil Engineer</h4>
				<h4 class="name"> John Doe</h4>
				<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.				</p>
			</div>
		</div>
		<div className="aboutpage-split">
			<img src={Img} class="about-img"></img>
			<div className="aboutpage-text">
				<h4 class="title"> Architect</h4>
				<h4 class="name"> John Doe</h4>
				<p>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.				</p>
			</div>
		</div>
	</div>
	</div>

);
