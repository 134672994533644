import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../images/project1.jpg';
import Img2 from '../images/project2.jpg';
import Img3 from '../images/project3.jpg';
import Img4 from '../images/project4.jpg';
import Img5 from '../images/project5.jpg';
import Img6 from '../images/project6.jpg';


export default () => (
	<div className='project'>
	<div class='animated reveal fade-bottom'>
		<h4>Explore Our Projects</h4>
		<Link class="proj-button" to='/contactpage'>
		<button className="project-button">GET IN TOUCH</button>
		</Link>
		<div class="project-split">

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img1}></img>
				<div class="project-overlay">
					<div class="project-text">Editorial Project 1</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img2}></img>
				<div class="project-overlay">
					<div class="project-text">Editorial Project 2</div>
				</div>
			</div>
			</a>


		</div>
	</div>
	</div>
);
