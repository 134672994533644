import React from 'react';
import Img_logo from '../images/construction.jpg';

export default () => (
	<div className='section2-CN'>

	<div class="service-split">
		<img class="service-split-img" src={Img_logo}></img>
		<div>
		<h4>
		Construction
		</h4>
		<p>
		10. Construction: Begin construction according to the plans and schedule. Monitor progress, quality, and costs. Handle any changes or issues that arise.
		</p>
		<p>
11. Inspections and Compliance: Schedule and pass necessary inspections to ensure the building complies with local building codes and regulations.
</p>
		<p>
12. Interior and Exterior Finishing: Complete the interior and exterior finishes of the building. This could include painting, flooring, fixtures and fittings, landscaping, etc.
</p>
		<p>
13. Final Inspections and Occupancy Permits: Arrange for final inspections and receive occupancy permits, indicating the building is safe for habitation.
</p>
		<p>
14. Closeout and Handover: Complete all final paperwork, pay all remaining bills, release any retainage to contractors, and officially hand over the building to the owner or operator.
</p>
		</div>
	</div>

	</div>
);
