import React from 'react';
import Img_logo from '../images/fp-post.jpeg';


export default () => (
	<div className='section3-CN'>

	<div class="service-split">
		<img class="service-split-img" src={Img_logo}></img>
		<div>
		<h4>
		Post Construction
		</h4>
		<p>
		15. Post-construction Services: Provide any post-construction services, such as warranties, maintenance, or management services, if applicable.
		</p>
		</div>
	</div>

	</div>
);
