import React from 'react';


export default () => (
	<div className='thankyou'>
	<div class="">
		<h4>
		THANK YOU!
		</h4>
	</div>
	</div>
);
