import React from 'react';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../images/project1.jpg';
import Img2 from '../images/project2.jpg';
import Img3 from '../images/project3.jpg';
import Img4 from '../images/project4.jpg';
import Img5 from '../images/project5.jpg';
import Img6 from '../images/project6.jpg';

export default () => (
	<div className='projects-page'>
        <h4>Our Projects</h4>
        <p>
                New Wave Property Group is Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
        </p>
        <Link to='/contactpage'>
        <button className="projectpage-button">GET IN TOUCH</button>
        </Link>
		<div class="projectpage-split">

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img1}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img2}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img3}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
					<img class="projectspage-img" src={Img4}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
				<img class="projectspage-img" src={Img5}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>

            <a class="editorial-item" href="/projects/project1">
			<div class="editorial-container">
			<img class="projectspage-img" src={Img2}></img>
				<div class="project-overlay">
					<div class="project-text">PROJECT XYZ</div>
				</div>
			</div>
			</a>


		</div>
	</div>
);
