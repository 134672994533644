import React from 'react';
import Img_logo from '../images/plan.png';

export default () => (
	<div className='section1-CN'>

	<div class="service-split">
		<img class="service-split-img" src={Img_logo}></img>
		<div>
		<h4>
		Pre Construction
		</h4>
		<p>
		1. Property and Site Selection: Identify a suitable property for development. Factors to consider include location, size, zoning, potential for profitability, and environmental considerations.
		</p>
		<p>
2. Feasibility Study and Preliminary Budgeting: Conduct feasibility studies to assess the viability of the project and to identify potential challenges. Make a rough budget projection.
</p>
		<p>
3. Acquisition: Purchase the land. This can include negotiations, legal documentation, and arranging financing.
</p>
		<p>
4. Site Assessment: Carry out a detailed assessment of the site. This can include a survey of the site's features, soil testing, environmental assessments, and assessment of utilities and infrastructure.
</p>
		<p>
5. Planning and Design: Engage architects and engineers to develop a plan for the building. This will include floor plans, elevations, and detailed drawings.
</p>
		<p>
6. Obtain Approvals: Submit plans to local government authorities to obtain necessary permits and approvals. This might include zoning permits, building permits, and environmental permits.
</p>
		<p>
7. Finalize Budgeting and Financing: With the design and approvals in hand, finalize your project budget. Secure the necessary financing.
</p>
		<p>
8. Hire Contractors: Interview and select construction contractors for the project. This may also include hiring project managers or construction management firms.
</p>
		<p>
9. Pre-construction Preparations: Carry out any necessary tasks before construction can begin, such as site clearing, installing site services, setting up site security, etc.
</p>
		</div>
	</div>
	</div>
 );
