import React from 'react';
//import $ from 'jquery';
import { Link }  from 'react-router-dom';
import { BsArrowRight } from "react-icons/bs";
import Img1 from '../../images/project/project1.jpg';
import Img2 from '../../images/project/project2.jpg';
import Img3 from '../../images/project/project3.jpg';
import Img4 from '../../images/project/project4.jpg';
import Img5 from '../../images/project/project5.jpg';
import Img6 from '../../images/project/project6.jpg';
import Img8 from '../../images/project/project.jpg';
import Img9 from '../../images/project/project7.jpg';
import Img10 from '../../images/project/project8.jpg';
import Img11 from '../../images/project1.jpg';

import Img7 from '../../images/project1-estate.jpg';

import {ImgOpener} from './../scroll';

 // portfolio

//  document.querySelector(".gallery ul li a")
//     .addEventListener("click", (event) => {
// 		var itemID =  document.querySelector('href');
// 		document.querySelector('.gallery ul').addClass('item_open');
// 		itemID.addClass('item_open');
// 	return false;
// });

// document.querySelector(".close")
//     .addEventListener("click", (event) => {
// 		document.querySelector('.port, .gallery ul').removeClass('item_open');
// 		return false;
// });

// document.querySelector(".gallery ul li a")
//     .addEventListener("click", (event) => {
// 		document.querySelector('html, body').animate({
// 			scrollTop: parseInt(document.querySelector("#top").offset().top)
// 		}, 400);
// });


export default () => (

	<div className='project-template'>
		<img className='main-project-img' src={Img7} ></img>

		<h4>The Nelson’s Project</h4>
		<h5>Designing and Building the Ultimate Home</h5>
		<p>I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. 
		I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. 
		</p>
		<Link className="proj-button" to='/contactpage'>
		<button className="projectpage-button">GET IN TOUCH</button>
		</Link>

		<div className='project-desc'>
			<img  src={Img2} ></img>
			<div className='project-desc-text' >
				<h5>Howard Backen Architectural Design </h5>
				<p class="p1">BUILT 2018 · 4,200 SQ FT · OTTAWA,ON · BACKEN GILLAM INTERIORS · CLAUDIA SCHMIDT LANDSCAPES </p>
				<p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci[ng] velit, sed quia</p>
			</div>

		</div>
		<div className="project-split">
				<img className="project-img" src={Img1} ></img>
				<img className="project-img" src={Img10}></img>
				<img className="project-img" src={Img3}></img>
				<img className="project-img" src={Img4}></img>
				<img className="project-img" src={Img5}></img>
				<img className="project-img" src={Img6}></img>
				<img className="project-img" src={Img7} ></img>
				<img className="project-img" src={Img8}></img>
				<img className="project-img" src={Img9}></img>
				<img className="project-img" src={Img10}></img>
				<img className="project-img" src={Img5}></img>
				<img className="project-img" src={Img4}></img>
		</div>


	</div>

	
);
